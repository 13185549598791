/* eslint-disable jsx-a11y/anchor-is-valid */
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';

export const VehiclePackagePagination = ({ pagination, onPageChange }: any) => {
    
    const updatePage = (page: number | null) => {
        if (page) {
            onPageChange(page)
        }
    }

    return (
        <div className='row m-4'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination'>
                        <ResponsivePagination
                            current={pagination?.page}
                            total={pagination?.totalPages}
                            onPageChange={updatePage}
                        />
                    </ul>
                </div>
            </div>
        </div>
    )
}

