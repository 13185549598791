/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState, useContext } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'react-bootstrap'
import { IPackages } from '../../../../app/modules/subscriptions/create/ISubscription'
import { StepperComponent } from '../../../assets/ts/components'
import { KTIcon } from '../../../helpers'
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { Step5 } from './steps/Step5'
import { Step6 } from './steps/Step6'
import { PackageContext } from '../../../../app/modules/subscriptions/context/SubscriptionContext'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'
import { getSubscriberById } from '../../../../app/modules/subscriptions/core/_requests'
import { Subscriptions } from '../../../../app/modules/subscriptions/core/_models'
import { PackageVehicle } from '../../../../app/modules/packages/packageVehicle/packageVehicle-list/core/_models'
import { getVehiclePackage } from '../../../../app/modules/packages/packageVehicle/packageVehicle-list/core/_requests'
import { Step7 } from './steps/Step7'

type Props = {
  show: boolean
  packageList: IPackages[]
  handleClose: () => void
  isEditID: any
  updateData: () => void | Promise<void>
  handleLoading: (isLoading: boolean) => void
  setModalData: (data: { icon: string; message: string }) => void
  handleSuccessShow: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

const CreatePackageModal = ({
  show,
  handleClose,
  packageList,
  isEditID,
  updateData,
  handleLoading,
  setModalData,
  handleSuccessShow,
}: Props) => {

  const [searchParams] = useSearchParams()
  const subscriptionIdParam = searchParams.get('id')

  const { packages, setPackage } = useContext(PackageContext)
  const [newPackage, setNewPackage] = useState({
    _id: '',
    packageType: '',
    packageId: '',
    planType: '',
    flexiRentQty: 0,
    planCost: '0',
    vehicle: {
      body_type: '',
      color: '',
      fuel_type: '',
      Make: '',
      Model: '',
      vehicle_name: '',
      registration: '',
      transmission: '',
      vin: '',
      year: '',
      _id: '',
    },
    total_KMs: '',
    contracted_services: '',
    tyres: 0,
    weekly_rate: '',
    accessories: [],
    signageOption: '',
    weekly_discount:0,
    deliveryDetails: {
      deliveryDateTime: '',
      delivery_Location: '',
      country: '',
      district_city: '',
      address_line_1: '',
      address_line_2: '',
      state_province: '',
      postal_code: '',
    },
    vehicleType: '',
    approve: false,
    completed: false,
  })

  useEffect(() => {
    if (isEditID) {
      setNewPackage(isEditID)
    } else {
      setNewPackage({
        _id: '',
        packageType: '',
        packageId: '',
        planType: '',
        flexiRentQty: 0,
        planCost: '0',
        vehicle: {
          body_type: '',
          color: '',
          fuel_type: '',
          Make: '',
          Model: '',
          vehicle_name: '',
          registration: '',
          transmission: '',
          vin: '',
          year: '',
          _id: '',
        },
        total_KMs: '',
        contracted_services: '',
        tyres: 0,
        weekly_rate: '',
        accessories: [],
        signageOption: '',
        weekly_discount:0,
        deliveryDetails: {
          deliveryDateTime: '',
          delivery_Location: '',
          country: '',
          district_city: '',
          address_line_1: '',
          address_line_2: '',
          state_province: '',
          postal_code: '',
        },
        vehicleType: '',
        approve: false,
        completed: false,
      })
    }
  }, [isEditID])

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [hasError, setHasError] = useState(false)
  const [customerDetails, setCustomerDetails] = useState<Subscriptions>()

  const [packageError, setPackageError] = useState('')
  const [packageVehicleTypeError, setPackageVehicleTypeError] = useState('')
  const [packageVehicleError, setPackageVehicleError] = useState('')
  const [stepCount, setStepCount] = useState(0)

  const [vehicleData, setVehicleData] = useState<PackageVehicle[]>([])

  useEffect(() => {
    getSubscriptionData()
    getVehiclePackage(0, ``).then((res: any) => {
      setVehicleData(res.data)
    })
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
  }

  const nextStep = async () => {
    setHasError(false)
    if (!stepper.current) {
      return
    }
    setStepCount(stepCount + 1)

    if (stepper.current.getCurrentStepIndex() === 1) {
      if (newPackage.packageType === '') {
        setPackageError('Please Select Package Type!')
        return
      } else {
        setPackageError('')
      }

      if (newPackage.packageType === 'Rent To Own') {
        if (newPackage.planType === '') {
          setPackageError('Please Select Package Plan!')
          return
        } else {
          setPackageError('')
        }
      }
    }

    if (stepper.current.getCurrentStepIndex() === 2) {
      if (newPackage.vehicle.vehicle_name === '') {
        setPackageVehicleError('Please Select Vehicle!')
        return
      } else {
        setPackageVehicleError('')
      }
    }

    if (stepper.current.getCurrentStepIndex() === 6) {
      const { deliveryDateTime, country, address_line_1, state_province, postal_code } =
        newPackage.deliveryDetails
      if (
        deliveryDateTime === '' ||
        country === '' ||
        address_line_1 === '' ||
        state_province === '' ||
        postal_code === ''
      ) {
        setPackageVehicleError('Please enter required delivery details!')
        return
      } else {
        setPackageVehicleError('') // Clear error if inputs are valid
      }
    }
    stepper.current.goNext()
  }

  useEffect(() => {
    const { deliveryDateTime, country, address_line_1, state_province, postal_code } =
      newPackage.deliveryDetails
    if (
      deliveryDateTime !== '' &&
      country !== '' &&
      address_line_1 !== '' &&
      state_province !== '' &&
      postal_code !== ''
    ) {
      setPackageVehicleError('')
    }
  }, [newPackage.deliveryDetails])

  const handleSelectPackage = (updatedPkg: any) => {
    setNewPackage(updatedPkg)
  }

  const getSubscriptionData = () => {
    getSubscriberById(subscriptionIdParam as string).then(async (response) => {
      setCustomerDetails(response)
      if (response?.packages) {
        const transformedPackages = response.packages.map((item) => ({
          _id: item._id || '',
          packageId: item.package_id || '',
          packageType: item.package_name || '',
          planType: item.plan_name || '',
          planCost: '',
          vehicleType: item.vehicletype || '',
          flexiRentQty: 0,
          total_KMs: item.total_KMs || '',
          contracted_services: item.contracted_services || '',
          weekly_discount: item.weekly_discount || 0,
          tyres: item.tyres || 0,
          vehicle: {
            body_type: '',
            color: '',
            fuel_type: '',
            Make: item.vehicle_make || '',
            Model: item.vehicle_model || '',
            vehicle_name: item.vehicle_name || '',
            registration: '',
            transmission: '',
            vin: '',
            year: '',
            _id: item.vehicle_id || '',
          },
          accessories: item.accessories || [],
          signageOption: item.signageOption || '',
          deliveryDetails: {
            deliveryDateTime: item.deliveryDetails.deliveryDateTime || '',
            delivery_Location: item.deliveryDetails.delivery_Location || '',
            country: item.deliveryDetails.country || '',
            district_city: item.deliveryDetails.district_city || '',
            address_line_1: item.deliveryDetails.address_line_1 || '',
            address_line_2: item.deliveryDetails.address_line_2 || '',
            state_province: item.deliveryDetails.state_province || '',
            postal_code: item.deliveryDetails.postal_code || '',
          },
          approve: item.approve,
          zoho_job_id: item.zoho_job_id,
          weekly_rate: item.weekly_rate,
          completed: false,
        }))
        setPackage(transformedPackages)
      }
    })
  }

  const calculateVehicleWeeklyRate = (item: any) => {
    let vehicleName = item?.vehicle_name ? item?.vehicle_name : item?.vehicle.vehicle_name
    let packageID = item?.package_id ? item?.package_id : item?.packageId
    const vehicle = vehicleData.find(v => v.vehicle_name === vehicleName)
    const plan = vehicle?.plans.find((p: any) => p.package_category._id === packageID);
    if (plan) {
      return plan.weekly_rate
    } else {
      return 0;
    }
  }

  const handleSubmit = (arr: any) => {
    handleClose()
    setStepCount(0)
    let packPayload = []
    newPackage.completed = true

    //For edit packages push the same new package inside exists the packages.....
    if (isEditID) {
      packPayload = packages.map((item) => {
        if (item._id === newPackage._id) {
          return { ...item, ...newPackage }
        }
        return item
      })
    } else {
      packPayload = [...packages, newPackage] as any
    }

    const updatedPackages = packPayload.map((item: any) => ({
      package_id: item.packageId,
      package_name: item.packageType,
      plan_name: item.planType,
      duration: `${parseInt(item.planType) >= 12 ? parseInt(item.planType) / 12 : item.planType}`,
      vehicle_name: item.vehicle.vehicle_name,
      accessories: item.accessories,
      signageOption: item.signageOption,
      weekly_discount:item.weekly_discount,
      total_KMs: item.total_KMs,
      contracted_services: item.contracted_services,
      tyres: item.tyres,
      weekly_rate: calculateVehicleWeeklyRate(item),
      deliveryDetails: {
        deliveryDateTime: item.deliveryDetails.deliveryDateTime,
        delivery_Location: item.deliveryDetails.delivery_Location,
        country: item.deliveryDetails.country,
        district_city: item.deliveryDetails.district_city,
        address_line_1: item.deliveryDetails.address_line_1,
        address_line_2: item.deliveryDetails.address_line_2,
        state_province: item.deliveryDetails.state_province,
        postal_code: item.deliveryDetails.postal_code,
      },
      zoho_job_id: item.zoho_job_id,
      approve: item.approve,
    }))
    setPackage(updatedPackages)
    handleUpdateSubscription(updatedPackages)
  }

  const handleUpdateSubscription = async (packPayload: any) => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')

    if (!customerDetails) {
      console.error('Customer details not available!')
      return
    }
    var raw = JSON.stringify({
      packages: packPayload,
      advance_information: {
        sales_representative_code: customerDetails.advance_information?.sales_representative_code,
        account_coordinator_name: customerDetails.advance_information?.account_coordinator_name,
        account_coordinator_code: customerDetails.advance_information?.account_coordinator_code,
      },
      firstname: customerDetails.firstname,
      lastname: customerDetails.lastname,
      hs_deal_id: customerDetails.hs_deal_id,
      subscription_date: moment(new Date()).format('YYYY-MM-DD'), // Ensure correct date format
    })
    try {
      handleLoading(true)
      const response = await fetch(
        `${BACKEND_API_URL}/subscriptions/update-subscriptions?subscription_id=${subscriptionIdParam}`,
        {
          method: 'PUT',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        }
      )
      await response.json()
      setModalData({
        icon: 'success',
        message: 'Rental Updated Successfully!',
      })
      handleLoading(false)
      await updateData()
      handleSuccessShow()
    } catch (error) {
      console.error('Error updating subscription:', error)
    }
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      // aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>Configure a package</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Packages</h3>

                    <div className='stepper-desc'>Please select a package</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Vehicle</h3>

                    <div className='stepper-desc'>Select a vehicle</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Vehicle Accessories</h3>

                    <div className='stepper-desc'>Select a Vehicle Accessories</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}

              {/* begin::Step 4*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Discounts</h3>

                    <div className='stepper-desc'>Select a weekly discount</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
                <div className='stepper-line h-40px'></div>
              </div>
              {/* end::Step 4*/}

              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>5</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Signage Option</h3>

                    <div className='stepper-desc'>Select a Signage Option</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
                <div className='stepper-line h-40px'></div>
              </div>
              {/* end::Step 5*/}

              {/* begin::Step 6*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>6</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Delivery Details</h3>

                    <div className='stepper-desc'>Enter a Delivery Details</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
                <div className='stepper-line h-40px'></div>
              </div>
              {/* end::Step 6*/}

              {/* begin::Step 7*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>7</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>Completed</h3>

                    <div className='stepper-desc'>Review and Submit</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 6*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form id='kt_modal_create_app_form'>
              <Step1
                packageList1={packageList}
                handleSelectPackage={handleSelectPackage}
                packages={newPackage}
              />
              <Step2 packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step3 packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step4 packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step5 packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step6 packages={newPackage} handleSelectPackage={handleSelectPackage} />
              <Step7 packages={newPackage} />
              {packageError && (
                <div className='fs-5 fw-semibold mb-4 mt-5 text-danger'>{packageError}</div>
              )}
              {packageVehicleTypeError && (
                <div className='fs-5 fw-semibold mb-4 mt-5 text-danger'>
                  {packageVehicleTypeError}
                </div>
              )}
              {packageVehicleError && (
                <div className='fs-5 fw-semibold mb-4 mt-5 text-danger'>{packageVehicleError}</div>
              )}

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTIcon iconName='arrow-left' className='fs-3 me-1' /> Previous
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    onClick={handleSubmit}
                  >
                    Submit <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                  </button>

                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    Next Step <KTIcon iconName='arrow-right' className='fs-3 ms-1 me-0' />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { CreatePackageModal }
