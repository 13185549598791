const QUERIES = {
  CUSTOMER_LIST: 'customer-list',
  COMPANY_LIST: 'company-list',
  USERS_LIST: 'users-list',
  VEHICLE_LIST: 'vehicle-list',
  PART_AND_ACCESSORIES_LIST: 'part-and-accessories-list',
  PENDING_APP_LIST: 'pending-app-list',
  ACTIVE_SUBSCRIPTION_LIST: 'active-subscription-list',
  CLOSED_SUBSCRIPTION_LIST: 'closed-subscription-list',
  ALL_APPLICATION_LIST: 'all-application-list',
  APPLICATION_UNDER_ASSESSMENT_LIST: 'application-under-assessment-list',
  CREDIT_ASSESSMENT_APPROVED: 'credit-assessment-approved',
  CREDIT_ASSESSMENT_DECLINED: 'credit-assessment-declined',
  CREDIT_ASSESSMENT_INCOMPLETE: 'credit-assessment-incomplete',
  all_ASSESSMENT_COMPLETE: 'credit-all-assessment-complete',
  TRANSACTION_LIST: 'transaction-list',
  INVOICES_LIST: 'invoices-list',
  PACKAGE_CATEGORY:'package-category',
  DISCOUNT:'discounts'
}

export { QUERIES }
