import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {
  User,
  UsersQueryResponse,
  InvoiceQueryResponse,
  Invoice,
  PaymentRequest,
  CreditRequest,
  InvoiceApiResponse,
} from './_models'
import {ISingleInvoice} from './ISingleInvoice'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`
// const GET_USERS_URL = `${API_URL}/users/query`
const GET_USERS_URL = `${BACKEND_API_URL}/invoices/get-xero-invoices`
const GET_SINGLE_INVOICE_URL = `${BACKEND_API_URL}/invoices/get-xero-invoices-by-id`
const MAKE_PAYMENT_URL = `${BACKEND_API_URL}/transactions/make-payment`
const CREATE_TRANSACTION_LINK = `${BACKEND_API_URL}/transactions/create-transaction-link`
const CREATE_CREDIT_URL = `${BACKEND_API_URL}/invoices/create-creditnote-and-allocate`


const getInvoicesLists = (query: string): Promise<InvoiceQueryResponse> => {
  // console.log('this is query string', query, invtype)
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<InvoiceQueryResponse>) => d.data)
}
const getInvoiceById = async (invoice_id: string): Promise<ISingleInvoice | undefined> => {
  return axios
    .get(`${BACKEND_API_URL}/invoices/invoices/get-xero-invoices-by-id?invoice=${invoice_id}`)
    .then((response: AxiosResponse<Response<ISingleInvoice>>) => response.data)
    .then((response: Response<ISingleInvoice>) => response.data)
}

const makeInvoicePayment = async (  
  invoiceData: PaymentRequest
): Promise<InvoiceApiResponse | undefined> => {
  try {
    const response: AxiosResponse<InvoiceApiResponse> = await axios.post(
      MAKE_PAYMENT_URL,
      invoiceData
    )

    // Access the response directly as InvoiceApiResponse
    const responseData: InvoiceApiResponse = response.data

    // Now you have access to the success, message, and other properties in responseData
    return responseData
  } catch (error) {
    console.error('Error making invoice payment:', error)
    throw error // You might want to handle errors appropriately based on your use case
  }
}

const createCreditAndAllocation = async (
  creditReqData: CreditRequest
): Promise<InvoiceApiResponse | undefined> => {
  try {
    const response: AxiosResponse<InvoiceApiResponse> = await axios.post(
      CREATE_CREDIT_URL,
      creditReqData
    )

    // Access the response directly as InvoiceApiResponse
    const responseData: InvoiceApiResponse = response.data

    // Now you have access to the success, message, and other properties in responseData
    return responseData
  } catch (error) {
    console.error('Error making invoice payment:', error)
    throw error // You might want to handle errors appropriately based on your use case
  }
}

const sendInvoicePaymentLink = async (
  invoiceData: any
): Promise<InvoiceApiResponse | undefined> => {
  console.log('invoiceData', invoiceData)
  try {
    const response: AxiosResponse<InvoiceApiResponse> = await axios.post(
      CREATE_TRANSACTION_LINK,
      invoiceData
    )

    // Access the response directly as InvoiceApiResponse
    const responseData: InvoiceApiResponse = response.data

    // Now you have access to the success, message, and other properties in responseData
    return responseData
  } catch (error) {
    console.error('Error making invoice payment:', error)
    throw error // You might want to handle errors appropriately based on your use case
  }
}

const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getInvoicesLists,
  deleteUser,
  deleteSelectedUsers,
  getUserById,
  createUser,
  updateUser,
  getInvoiceById,
  makeInvoicePayment,
  sendInvoicePaymentLink,
  createCreditAndAllocation,
}
