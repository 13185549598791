/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import { KTIcon } from '../../../../helpers'

type Props = {
  className: string
}

// Add the URL field for each row
const rows: Array<{ description: string, url: string }> = [
  { description: 'Credit Watch', url: 'https://login.creditorwatch.com.au/?redirectUrl=https://app.creditorwatch.com.au/debtor-management/' },
  { description: 'Xero', url: 'https://www.xero.com/au/' },
  { description: 'PPSR', url: 'https://transact.ppsr.gov.au/ppsr/Login' },
]

const ListsWidget26 = ({ className }: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <h3 className='card-title text-gray-800 fw-bold'>Links</h3>
      <div className='card-toolbar'></div>
    </div>
    <div className='card-body pt-5'>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <a
              href={row.url}
              className='text-primary fw-semibold fs-6 me-2'
              target='_blank'
              rel="noreferrer"
            >
              {row.description}
            </a>
            <button
              type='button'
              className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
            >
              <KTIcon iconName='exit-right-corner' className='fs-2' />
            </button>
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)

export { ListsWidget26 }
