/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { useThemeMode } from '../../layout/theme-mode/ThemeModeProvider'
import { SubsCreditWatchDetailLoading } from '../../../../app/modules/subscriptions/subscriptions-cw-list/components/loading/SubsCreditWatchDetailLoading'

type Props = {
  className: string
  transactions: any
  isLoading: any
}

const ChartsWidget_transactions: React.FC<Props> = ({ className, transactions, isLoading }) => {

  const chartRef = useRef<HTMLDivElement | null>(null)
  const { mode } = useThemeMode()

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, transactions))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, transactions])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Transactions</span>
          <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span>
        </h3>
      </div>
      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{ height: '350px' }}></div>
      </div>
      {isLoading ? <SubsCreditWatchDetailLoading /> : null}
    </div>
  )
}

export { ChartsWidget_transactions }

function getChartOptions(height: number, transactions: { date: string; amount: number; count: number }[]): ApexOptions {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  const dates = transactions?.map(transaction => transaction.date)
  const amounts = transactions?.map(transaction => transaction.amount)
  const counts = transactions?.map(transaction => transaction.count)

  return {
    series: [
      {
        name: 'Transaction Amount',
        data: amounts,
      },
      {
        name: 'Transaction Count',
        data: counts,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor, '#b2e2fd'], // Different color for the count series
    },
    xaxis: {
      categories: dates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
          formatter: function (val) {
            return val.toFixed(2); // Format to two decimal places
          },
        },
      },
      {
        opposite: true,
        labels: {
          style: {
            colors: labelColor,
            fontSize: '12px',
          },
          formatter: function (val) {
            return val.toFixed(2); // Format to two decimal places
          },
        },
      },
    ],
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, { seriesIndex }) {
          return seriesIndex === 0 ? '$' + val.toFixed(2) : val + ' transactions'
        },
      },
    },
    colors: [lightColor, '#b2e2fd'], // Different color for the count series
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}



