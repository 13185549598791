/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Transaction} from '../../core/_models'

type Props = {
  invoice: Transaction
}

const TransactionInfoCell: FC<Props> = ({invoice}) => {
  return (
    <div className='d-flex align-items-center'>
      {/* begin:: Avatar */}
      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'></div>
      <div className='d-flex flex-column'>
        <span className='text-gray-800 mb-1'>
          {invoice?.ReceiptNumber}
        </span>
        {/* <span> {invoice?.InvoiceID}</span> */}
      </div>
    </div>
  )
}

export {TransactionInfoCell}
