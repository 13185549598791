import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { PackageVehicle, UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`

const GET_PACKAGE_LIST = `${BACKEND_API_URL}/package/get-all-package`
const ADD_NEW_PACKAGE = `${BACKEND_API_URL}/package/create-package`
const EDIT_PACKAGE = `${BACKEND_API_URL}/package/edit-package`
const PACKAGE_CATEGORY = `${BACKEND_API_URL}/package/delete-package`
const GET_PACKAGE_BY_ID = `${BACKEND_API_URL}/package/get-package-id`


const getVehiclePackage = async (page: number, search: string): Promise<UsersQueryResponse> => {
  const query = `page=${page}`;
  const d = await axios.get(`${GET_PACKAGE_LIST}?${query}&${search}`)
  return d.data
}

const createVehiclePackages = (pack: PackageVehicle): Promise<PackageVehicle | undefined> => {
  return axios
    .post(`${ADD_NEW_PACKAGE}`, pack)
    .then((response: AxiosResponse<PackageVehicle>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const updateVehiclePackages = (pack: PackageVehicle): Promise<PackageVehicle | undefined> => {
  return axios
    .put(`${EDIT_PACKAGE}?id=${pack._id}`, pack)
    .then((response: AxiosResponse<PackageVehicle>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getPackagesById = (id: ID): Promise<PackageVehicle | undefined> => {
  return axios
    .get(`${GET_PACKAGE_BY_ID}?id=${id}`)
    .then((response: AxiosResponse<Response<PackageVehicle>>) => response.data)
    .then((response: Response<PackageVehicle>) => response.data)
}

const deleteVehiclePackages = (userId: ID): Promise<void> => {
  return axios.delete(`${PACKAGE_CATEGORY}?id=${userId}`).then(() => { })
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getVehiclePackage, deleteVehiclePackages, deleteSelectedUsers, getPackagesById, createVehiclePackages, updateVehiclePackages }
