import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
// console.log('backend url', API_URL, BACKEND_API_URL)
export const GET_USER_BY_ACCESSTOKEN_URL = `${BACKEND_API_URL}/user/get-user-by-token`
export const LOGIN_URL = `${BACKEND_API_URL}/user/login`
export const VERIFY_2FA_URL = `${BACKEND_API_URL}/user/verify-two-factor-auth`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${BACKEND_API_URL}/user/forgot-password`
export const RESET_PASSWORD_URL = `${BACKEND_API_URL}/user/reset-password`
export const REQUEST_LOGOUT = `${BACKEND_API_URL}/user/logout`

// Server should return AuthModel
// export function login(email: string, password: string) {
//   return axios.post<AuthModel>(LOGIN_URL, {
//     email,
//     password,
//   })
// }

export const login = (data: any) => {
  try {
    return axios
      .post<AuthModel>(LOGIN_URL, data)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        const {response} = error
        const {data: errors} = response
        return errors
      })
  } catch (err) {
    console.log(err)
  }
}

export function verify2FactorAuthentication(
  email: string,
  authenticationCode: number,
  tofa: string
) {
  return axios.post<AuthModel>(VERIFY_2FA_URL, {
    email,
    authenticationCode,
    tofa,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function resetPassword(password: string, token: any) {
  const response = await axios.post(`${RESET_PASSWORD_URL}/${token}`, {
    password,
  })
}

// export const resetPassword = async (email: string): Promise<UserModel | undefined> => {
//   const response = await axios.post(`${REQUEST_LOGOUT}?userId=${userId}`)
//   return response.data
// }

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {},
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         Authorization: token,
//       },
//     }
//   )
// }

export const getUserByToken = (token: string) => {
  try {
    return axios
      .post<AuthModel>(GET_USER_BY_ACCESSTOKEN_URL, token)
      .then((response) => {
        return response
      })
      .catch((error) => {
        const {response} = error
        const {data: errors} = response
        return errors
      })
  } catch (err) {
    console.log(err)
  }
}

export const logoutUser = (userId: UserModel): Promise<UserModel | undefined> => {
  return axios.post(`${REQUEST_LOGOUT}?userId=${userId}`).then((response: any) => response.data)
}
