import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {PartsAndAccessories, PartsAndAccessoriesQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`

const GET_ACCESSORIES_URL = `${BACKEND_API_URL}/vehicle-accessories`
const Create_Vehicle_Accessories =  `${BACKEND_API_URL}/vehicle-accessories/add-vehicle-accessories`
const Edit_Vehicle_Accessories = `${BACKEND_API_URL}/vehicle-accessories/edit-vehicle-accessories`
const Delete_Vehicle_Accessories = `${BACKEND_API_URL}/vehicle-accessories/delete-vehicle-accessories`
const GET_Vehicle_ByID = `${BACKEND_API_URL}/vehicle-accessories/getById`

const getPartsAndAccessories = async (query: string): Promise<PartsAndAccessoriesQueryResponse> => {
  const res = await axios.get(`${GET_ACCESSORIES_URL}/get-vehicle-accessories?${query}`)
  return res.data
}

const addVehicleAccessories = (parts: PartsAndAccessories): Promise<PartsAndAccessories | undefined> => {
  return axios
    .post(`${Create_Vehicle_Accessories}`, parts)
    .then((response: AxiosResponse<PartsAndAccessories>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const editVehicleAccessories = (parts: PartsAndAccessories): Promise<PartsAndAccessories | undefined> => {
  return axios
    .put(`${Edit_Vehicle_Accessories}?id=${parts._id}`, parts)
    .then((response: AxiosResponse<PartsAndAccessories>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getPartsById = (id: ID): Promise<PartsAndAccessories | undefined> => {
  return axios
    .get(`${GET_Vehicle_ByID}?id=${id}`)
    .then((response: AxiosResponse<Response<PartsAndAccessories>>) => response.data)
    .then((response: Response<PartsAndAccessories>) => response.data)
}

const deleteParts = (userId: ID): Promise<void> => {
  return axios.delete(`${Delete_Vehicle_Accessories}?id=${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getPartsAndAccessories, deleteParts, deleteSelectedUsers, getPartsById, addVehicleAccessories, editVehicleAccessories}
