import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../_metronic/helpers'
import { Discount, UsersQueryResponse } from './_models'

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL

const GET_DISCOUNT_LIST = `${BACKEND_API_URL}/rate-discount/get-all-list`
const ADD_NEW_DISCOUNT = `${BACKEND_API_URL}/rate-discount/create`
const EDIT_DISCOUNT = `${BACKEND_API_URL}/rate-discount/edit-by-id`
const DELETE_DISCOUNT = `${BACKEND_API_URL}/rate-discount/delete-by-id`
const GET_DISCOUNT_BY_ID = `${BACKEND_API_URL}/rate-discount/get-by-id`

const getDiscounts = async (query: string): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_DISCOUNT_LIST}?${query}`)
  return d.data
}

const createDiscount = (cat: Discount): Promise<Discount | undefined> => {
  return axios
    .post(`${ADD_NEW_DISCOUNT}`, cat)
    .then((response: AxiosResponse<Discount>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const updateDiscount = (cat: Discount): Promise<Discount | undefined> => {
  return axios
    .put(`${EDIT_DISCOUNT}?id=${cat._id}`, cat)
    .then((response: AxiosResponse<Discount>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getDiscountById = (id: ID): Promise<Discount | undefined> => {
  return axios
    .get(`${GET_DISCOUNT_BY_ID}?id=${id}`)
    .then((response: AxiosResponse<Response<Discount>>) => response.data)
    .then((response: Response<Discount>) => response.data)
}

const deleteDiscount = (catId: ID): Promise<void> => {
  return axios.delete(`${DELETE_DISCOUNT}?id=${catId}`).then(() => { })
}

const deleteSelectedDiscount = (catIds: Array<ID>): Promise<void> => {
  const requests = catIds.map((id) => axios.delete(`${DELETE_DISCOUNT}/${id}`))
  return axios.all(requests).then(() => { })
}

export { getDiscounts, deleteDiscount, deleteSelectedDiscount, getDiscountById, createDiscount, updateDiscount }
